// External dependencies
import * as imageConversion from 'image-conversion';

export function convertImageToBase64(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader?.readAsDataURL(file);

    fileReader.onloadend = function () {
      resolve(fileReader.result);
    };

    fileReader.onerror = function () {
      reject(fileReader.result);
    };
  });
}

async function compressedImage(file) {
  const res = await imageConversion.compressAccurately(file, 200);
  const image = await convertImageToBase64(res);

  return image;
}

export default compressedImage;
