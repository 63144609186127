// External dependencies
import { Div, Icon } from 'atomize';
import { navigate } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app//redux/store';
import { listArticles } from '@entities/articles/redux/list-articles-redux/actions';
import Table, { RowSelectionType } from '@shared/ui/table/Table';
import { setLocalStorage } from '@shared/utils/helpers';
import { getDateTime } from '@shared/utils/renderDate';
import { getUserRole, UserRoles } from '@shared/utils/roles';
import Header from '@shared/ui/admin/header/Header';
import ManagerControlBarArticles from './ManagerControlBarArticles';
import { getTableColumnsForListArticles } from '../../../lib/helper';

export default function ListArticles() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { articles, currentPage, loading, searchString, total } = useSelector(
    (state: RootState) => state.listArticles,
  );

  function getImage(url: string) {
    return (
      <Div d="inline-block" p="10px">
        <Div d="flex" justify="center" align="center">
          {url ? (
            <img src={url} width="60px" />
          ) : (
            <Icon name="Photo" size="60px" />
          )}
        </Div>
      </Div>
    );
  }

  function navigateToArticleDetails(id: string) {
    setLocalStorage('articleId', id);

    navigate('article-details');
  }

  function onPageChange(page) {
    dispatch(listArticles(searchString, page));
  }

  try {
    return (
      <Div m="0 auto" className="container">
        <Header />
        <Table
          items={articles?.map((article) => ({
            id: article?.id,
            image: getImage(article?.heroMedia?.imageUrl),
            title: article?.title,
            category: article?.category,
            createDate: getDateTime(article?.createDate),
            synopsis: article?.synopsis,
          }))}
          onClickItem={navigateToArticleDetails}
          columns={getTableColumnsForListArticles()}
          currentPage={currentPage}
          emptyMessage="noArticles"
          controlBar={getControlBar()}
          loading={loading}
          onPageChange={onPageChange}
          stickyCol={2}
          searchString={searchString}
          selectionType={RowSelectionType.NUMBER}
          topBar={''}
          totalItemsCount={total}
        />
      </Div>
    );
  } catch (error) {
    return (
      <Div className="loading">
        <Div className="loading__icon"></Div>
      </Div>
    );
  }

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (true) {
      case userRole.includes(UserRoles.MANAGER):
        return <ManagerControlBarArticles />;

      case userRole.includes(UserRoles.MEDUCATION_MANAGER):
        return <ManagerControlBarArticles />;

      case null: {
        throw new Error('client has not set up yet');
      }

      default:
        throw new Error('Permission denied');
    }
  }
}
